import React, { Component } from 'react';

class ExperienceCard extends Component {
  renderBullets() {
    if (this.props.bullets) {
        return <ul>
          {
            this.props.bullets.map(function(bullet, index) {
              return <li key={ index }>{bullet}</li>;
            })
          }
        </ul>
    }
  }

  render() {
    return (
  		<div className="row mt-3">
  			<div className="col-12">
  				<div className="card">
  					<div className="card-body">

          		<div className="row">
          			<div className="col-12 col-md-3">
        					<div className="card-img">
        						<img alt='sample' src={this.props.image} className="img-fluid" />
        					</div>
        				</div>
          			<div className="col-12 col-md-9">
      						<h4 className="card-title">{this.props.title}</h4>
      						<h6 className="card-title">{this.props.dates}</h6>
                  {this.renderBullets()}
      						<p className="card-text"></p>
        				</div>
        			</div>

  					</div>
  					<div className="card-footer">
  						<a className="card-link" data-toggle="collapse" href={"#experienceDetails" + this.props.id} role="button" aria-expanded="false" aria-controls={"#experienceDetails" + this.props.id}>
                Read more<i className="ml-2 fa fa-arrow-down" />
              </a>

              <div className="collapse" id={"experienceDetails" + this.props.id}>
                <div className="card card-body">
                  {this.props.body}
                </div>
              </div>

  					</div>
  				</div>
  			</div>
  		</div>
    );
  }
}

export default ExperienceCard;
