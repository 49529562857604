import React, { Component } from 'react';

import './App.css';

import Header from './Header'
import Blog from './Blog'
import Portfolio from './Portfolio'
import Experience from './Experience'
import ContactMe from './ContactMe'
import Skills from './Skills'
import Education from './Education'
import Publications from './Publications'
/*import BarChart from './BarChart'*/
/*<BarChart />*/

class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        <Skills />
        <Blog />
        <Education />
        <Portfolio />
        <Publications />
        <Experience />
        <ContactMe />
      </div>
    );
  }
}

export default Home;
