import React, { Component } from 'react';
import './Publications.css';

class Publications extends Component {
  render() {
    return (
      <div className="row mt-3">
      	<div className="col-12">
      		<div className="card">
      			<div className="card-body">

          		<div className="row">
          			<div className="col-12">
      						<h5 className="card-title"><u>{this.props.title}</u>
                    <a href={this.props.publication_link} target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-external-link" />
                    </a>
                  </h5>
      						<h6 className="card-title"><strong>Journal</strong>:
                    <a href={this.props.journal_link} target="_blank" rel="noopener noreferrer">
                      <strong className='ml-1'>{this.props.journal}</strong>
                    </a>
                  </h6>
      						<h6 className="card-title"><strong>Publication Date</strong>: {this.props.publication_date}</h6>
      						<h6 className="card-title"><strong>Authors</strong>: {this.props.authors}</h6>
      						<p className="card-text">
                  </p>
        				</div>
        			</div>

      			</div>
      			<div className="card-footer">
      				<a className="card-link" data-toggle="collapse" href={"#publicationDetails" + this.props.id} role="button" aria-expanded="false" aria-controls={"#publicationDetails" + this.props.id}>
                Abstract: <i className="ml-2 fa fa-arrow-down" />
              </a>

              <div className="collapse" id={"publicationDetails" + this.props.id}>
                <div className="card card-body">
                  {this.props.summary}
                </div>
              </div>

      			</div>
      		</div>
      	</div>
      </div>
    );
  }
}

export default Publications;
