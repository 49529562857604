import React, {Component} from 'react'

class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      site_name: "cbusick.com"
    }
  }

  render() {
    return <div id='body' className='header-margin'>
      <div className='container'>
      <div className='row'>
      <div className='col text-left'>
        <div>
        	<h2 className='text-center'>Terms of Use for {this.state.site_name}</h2>
        	<div>
        		<p><strong>1. PRIVACY</strong></p>
        		<p>Please review our Privacy Notice to understand our privacy practices.</p>
        		<p><strong>2. GENERAL</strong></p>
        		<p>This page states the Terms of Use (“Terms”) under which you may use {this.state.site_name} (the “Site”), an on-line service to discover and purchase new and unique products and gifts.<br/>These Terms include the {this.state.site_name} Privacy Policy which is incorporated
        			into these Terms by reference. Please read these Terms carefully. If You do not accept the Terms stated here, do not use the Site and its services. By registering for and using the Site, You expressly agree to be bound by these Terms, including the
        			{this.state.site_name} Privacy Policy.</p>
        		<p>{this.state.site_name} may revise these Terms at any time by posting an updated version to the Site. You should visit this page periodically to review the most current Terms because they are binding on You. The terms “You” and “User” as used herein refer to all individuals
        			and/or entities accessing the Site for any reason. The term “Post” as used herein shall mean information that You submit, publish, or display on the Site.</p>
        		<p><strong>3. ELIGIBILITY</strong></p>
        		<p>By visiting the Site and accepting these Terms, You represent and warrant to {this.state.site_name} that You have the right, authority and capacity to agree to and abide by these Terms. You also represent and warrant to {this.state.site_name} that You will use the Site in a manner consistent
        			with any and all applicable laws and regulations.</p>
        		<p><strong>4. LICENSE</strong></p>
        		<p>{this.state.site_name} grants you a non-exclusive, non-transferable, limited license to access and make personal use of the Site and not to download (other than page caching) or modify it, or any portion of it, except with the express written consent of {this.state.site_name}. This
        			license allows people searching for unique products and gifts to purchase those products for personal use. Users are further prohibited from making any derivative use of the Site or its Content; downloading or copying any account information or listing
        			information for the benefit of any third party; or using any “robots,” “spiders,” or similar data-gathering, data-mining, or data extraction tools. All content on the Site is the property of {this.state.site_name}, and no portion of the Site may be reproduced, duplicated,
        			copied, sold, resold, visited, or otherwise exploited for any commercial purpose without the express written consent of {this.state.site_name}.</p>
        		<p>Any unauthorized use terminates the permission or license granted by {this.state.site_name} and may result in both civil and criminal liability.</p>
        		<p><strong>5. SITE RESTRICTIONS</strong></p>
        		<p>Users may not use the Site in order to transmit, post, distribute, store or destroy material, including, without limitation, comments and reviews (the “Content”) (i) in violation of any applicable law or regulation, (ii) in a manner that will infringe
        			the copyright, trademark, trade secret or other intellectual property rights of others or violate the privacy, publicity or other personal rights of others, or (iii) that is defamatory, obscene, threatening, abusive or hateful.</p>
        		<p>Users are also prohibited from violating or attempting to violate the security of the Site, including, without limitation, the following activities: (i) accessing data not intended for such User or logging into a server or account which the User is
        			not authorized to access; (ii) attempting to probe, scan, or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; (iii) attempting to interfere with service to any User, host or
        			network, including, without limitation, via means of submitting a virus to the Site, overloading, “flooding”, “spamming”, “mailbombing”, or “crashing”; or (iv) forgoing any TCP/IP packet header or any part of the header information in any email or
        			posting on the Site. Attempts to violate system or network security immediately terminate the permission or license granted by {this.state.site_name} and may result in civil and/or criminal liability.</p>
        		<p><strong>6. PROHIBITED USES</strong></p>
        		<p>Listing of available products on the Site may be used only for lawful purposes by Users of the Site. You may not frame or utilize framing techniques to enclose any trademark, logo, copyrighted image, or other proprietary information (including images,
        			text, page layout, or form) of {this.state.site_name} without express written consent. You may not use any meta tags or any other “hidden text” utilizing {this.state.site_name}’s name or trademarks without the express written consent of {this.state.site_name}. You agree not to sell or modify any content
        			found on the Site including, but not limited to, names of Users and Content, or to reproduce, display, publicly perform, distribute, or otherwise use the Content, in any way for any public purpose, in connection with products or services that are not
        			those of {this.state.site_name}, in any other manner that is likely to cause confusion among consumers, that disparages or discredits {this.state.site_name} or its licensors, that dilutes the strength of {this.state.site_name}’s or its licensor’s property, or that otherwise infringes {this.state.site_name}’s or its
        			licensor’s intellectual property rights. You further agree to in no other way misuse the Content that appears on the Site. The use of the Content on any other website or in a networked computer environment for any purpose is prohibited. Any code that {this.state.site_name} creates to generate or display any Content of the pages making up the Site is also protected by {this.state.site_name}’s copyright, and you may not copy or adapt such code.</p>
        		<p>{this.state.site_name} has no duty to monitor any materials posted, transmitted, or communicated to or with the Site. If you believe that something on the Site violates these Terms please contact our designated agent as set forth below.</p>
        		<p>If notified by a User of any materials which allegedly do not conform to these Terms, {this.state.site_name} may in its sole discretion investigate the allegation and determine whether to take any other actions or remove or request the removal of the Content. {this.state.site_name}
        			has no liability or responsibility to Users for performance or nonperformance of such activities.</p>
        		<p><strong>7. ELECTRONIC COMMUNICATION</strong></p>
        		<p>When you visit the Site or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Site. You agree that all agreements
        			notices, disclosure, and other communicates that we provide to you electronically satisfy any legal requirements that such communications be in writing.</p>
        		<p><strong>8. COPYRIGHT</strong></p>
        		<p>All content included on this Site, such as text, graphics, logos, icons, images, data compilations, and software, is the property of {this.state.site_name} or its content suppliers and protected by United States and international copyright laws. The compilation of all
        			content on this Site is the exclusive property of {this.state.site_name} and protected by U.S. and international copyright laws. All software used on this Site is the property of {this.state.site_name} or its software suppliers and protected by U.S. and international copyright laws.</p>
        		<p><strong>9. TRADEMARKS</strong></p>
        		<p>{this.state.site_name}, and other {this.state.site_name} graphics, logos, page headers, icons, images, and service names are trademarks, registered trademarks, or trade dress of {this.state.site_name} in the U.S. and/or other countries. {this.state.site_name}’s trademarks and trade dress may not
        			be used in connection with any product or service that is not {this.state.site_name}’s, in any manner that is likely to cause confusion among the public, or in any manner that disparages or discredits {this.state.site_name}. All other trademarks not owned by {this.state.site_name} that may appear on
        			this Site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by {this.state.site_name}.</p>
        		<p><strong>10. YOUR ACCOUNT</strong></p>
        		<p>If you use the Site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account and password.
        			You also agree to not disclose any personally identifiable data, including, but not limited to, first and last names, credentials, or other information of a personal nature (“Personal Data”) obtained from the Site. Your disclosure of any Personal Data
        			obtained from the Site may result in the immediate termination of your account. {this.state.site_name} further reserves the right to refuse service, terminate accounts, and remove or edit Content at its sole discretion.</p>
        		<p>{this.state.site_name} does not represent or guarantee the truthfulness, accuracy, or reliability of Content on the site, including Personal Data. Each User is responsible for updating and revising any relevant account information when necessary to maintain the truthfulness,
        			accuracy, or reliability of the information.</p>
        		<p><strong>11. REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT</strong></p>
        		<p>Registered Users of the Site may post reviews and comments of a product or service purchased via the Site, so long as the Content is not illegal, obscene, threatening, defamatory, an invasive of privacy, infringing of intellectual property rights, or
        			otherwise injurious to third parties or objectionable and does not consist of or contain commercial solicitation, mass mailings, or any form of “spam.” You may not use another User’s account to impersonate a User or entity, or otherwise mislead as
        			to the origin of the remarks. {this.state.site_name} reserves the right (but is not obligated) to remove or edit such Content, but does not regularly review posted Content.</p>
        		<p>If you post a review or submit comments, and unless {this.state.site_name} indicates otherwise, you grant {this.state.site_name} a nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative
        			works from, distribute, and display such content throughout the world, in any media. You grant {this.state.site_name} and sublicensees the right to use your name in connection with such Content, if they choose. You represent and warrant that You own or otherwise control
        			all of the rights to the content that You post; that the content is accurate; that use of the content You supply does not violate this policy and will not cause injury to any person or entity; and that You will indemnify {this.state.site_name} for all claims resulting
        			from Content You supply. {this.state.site_name} has the right but not the obligation to monitor and edit or remove any activity or Content. {this.state.site_name} takes no responsibility and assumes no liability for any content posted by You or any third party.</p>
        		<p><strong>12. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</strong></p>
        		<p>The Site may display affiliate products or services. Neither {this.state.site_name} nor the Site has control over the quality or fitness for a particular purpose of a product. {this.state.site_name} also has no control over the accuracy, reliability, completeness,
        			or timeliness of the User-submitted information and makes no representations or warranties about any information on the Site<br/>THE SITE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND SERVICES INCLUDED ON OR OTHERWISE MADE
        			AVAILABLE TO YOU THROUGH THIS SITE ARE PROVIDED BY {this.state.site_name} ON AN “AS IS” AND “AS AVAILABLE” BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. {this.state.site_name} MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR
        			THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK.</p>
        		<p>TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, {this.state.site_name} DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. {this.state.site_name} DOES NOT WARRANT THAT THIS SITE; INFORMATION,
        			CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITE; ITS SERVERS; OR EMAIL SENT FROM {this.state.site_name} ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. {this.state.site_name} WILL NOT BE LIABLE FOR ANY DAMAGES
        			OF ANY KIND ARISING FROM THE USE OF THE SITE OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL,
        			PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING. UNDER NO CIRCUMSTANCE SHALL {this.state.site_name}’S LIABILITY ARISING OUT OF OR IN CONNECTION WITH THE SITE OR YOUR USE OF THE SITE, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN CONTRACT,
        			TORT, BREACH OF WARRANTY OR OTHERWISE), EXCEED $100.</p>
        		<p><strong>13. INDEMNITY</strong></p>
        		<p>You agree to defend, indemnify, and hold harmless {this.state.site_name}, its affiliates, and their respective officers, directors, employees and agents, from and against any claims, actions or demands, including without limitation reasonable legal, accounting, and
        			other service provider fees, alleging or resulting from (i) any Content of other material You provide to the Site, (ii) Your use of any Content, or (iii) Your breach of the terms of these Terms. {this.state.site_name} shall provide notice to You promptly of any such
        			claim, suit, or proceeding.</p>
        		<p><strong>14. APPLICABLE LAW</strong></p>
        		<p>By using the Site, you agree that the laws of the State of Florida, without regard to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise between you and {this.state.site_name}.</p>
        		<p><strong>15. DISPUTES</strong></p>
        		<p>Any dispute relating in any way to Your visit to the Site or to services obtained through the Site shall be adjudicated in any state or federal court having jurisdiction over Maricopa County, Arizona, and You consent to exclusive jurisdiction and
        			venue in such courts.</p>
        		<p><strong>16. SITE POLICIES, MODIFICATION, AND SEVERABILITY</strong></p>
        		<p>Please review our Privacy Policy also posted on the Site. This policy also governs your use of the Site and is incorporated by reference as part of the Terms of Use for the Site. {this.state.site_name} reserves the right to make changes to the Site, policies, and these
        			Terms of Use at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining conditions.</p>
        	</div>
        </div>
      </div>
      </div>
      </div>
    </div>
  }
}

export default Privacy;
