import React, { Component } from 'react';
import './Header.css';
import './App.css';
import { Link } from "react-scroll";
import myFace from'./images/cbusick.png';

class Header extends Component {
  render() {
    return (
      <div>
      <header className="App-header header">
        <div className="overlay"></div>

        <div className="container mt-5">
          <div className="greetings">
            <h1>Hi, I am Chris Busick, nice to meet you.</h1>
          </div>
        </div>

        <div className="about on-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 text-center blah">
               <img alt='Chris Busick' src={myFace} className="myface mx-auto mb-3" />
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 desc">
                I am a Full Stack Software Engineer with 10 years of experience in a diverse set of industries: E-commerce, Supply Chain Distribution, and Medical/Genetics research.
                I help companies optimize and automate business processes, develop new systems and features, and scale infrastructure.
                I wear many hats and work closely with customers and coworkers when designing new features, products, and services in an iterative and collaborative fashion.
                I make make people’s lives easier through the application and automation of technology.
              </div>
            </div>
          </div>
        </div>

      <div className='d-flex justify-content-center mt-4 mb-4 on-top'>
        <Link smooth={true} duration={500} offset={-75} to="skills">
          <i className='fa fa-arrow-down fa-2x circle-icon'/>
        </Link>
      </div>

      </header>

      </div>
    );
  }
}

export default Header;
