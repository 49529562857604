import $ from 'jquery';
import { ApiURL } from './config.js'

export default {
  get(route, success, error) {
    $.ajax({
        type: 'GET',
        url: ApiURL() + route,
        dataType: 'json',
        contentType: 'application/json'
    })
    .done(success)
    .fail(error)
  },
  delete(route, success, error) {
    $.ajax({
        type: 'DELETE',
        url: ApiURL() + route,
        dataType: 'json',
        contentType: 'application/json'
    })
    .done(success)
    .fail(error)
  },
  post(route, payload, success, error) {
    $.ajax({
        type: 'POST',
        url: ApiURL() + route,
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(payload)
    })
    .done(success)
    .fail(error)
  },
  put(route, payload, success, error) {
    $.ajax({
        type: 'PUT',
        url: ApiURL() + route,
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(payload)
    })
    .done(success)
    .fail(error)
  }
}
