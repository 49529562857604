import React, { Component } from 'react';
import ApiService from './services/home_api'

import './ContactMe.css';


class ContactMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      email_address: '',
      message: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  handleSubmit(event) {
    this.persist()
    event.preventDefault();
  }

  persist() {
    ApiService.post('/message', this.state,
      response => {
        this.setState({submitted: true})
      },
      error => {
        console.log(error);
      }
    )

  }
  render() {
    if (this.state.submitted) {
      return <div className="container">
         <div className="alert alert-success m-4">
          Thanks for your message. I will read and respond when I get a chance.
        </div>
      </div>
    } else {
      return (
        <div className="contact-form">
          <div id="contact" className="anchor"></div>
          <div className="container">
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <h1>Get in Touch</h1>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 right">
                  <div className="form-group">
                    <input required type="text" className="form-control form-control-lg" placeholder="Your Name" name="from" value={this.state.from} onChange={this.handleChange}  />
                  </div>
                  <div className="form-group">
                    <input required type="email" className="form-control form-control-lg" placeholder="YourEmail@email.com" name="email_address" value={this.state.email_address} onChange={this.handleChange} />
                  </div>
                  <div className="form-group">
                    <textarea required name='message' className="form-control form-control-lg" value={this.state.message} onChange={this.handleChange}></textarea>
                  </div>
                  <input type="submit" className="btn btn-secondary btn-block" value="Send" name="" />
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}

export default ContactMe;
