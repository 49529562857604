import React, { Component } from 'react';
import uaImage from'./images/uofa.jpg';

class UACard extends Component {
  render() {
    return (
  		<div className="row mt-3">
  			<div className="col-12">
  				<div className="card">
  					<div className="card-body">

          		<div className="row">
                <div className="col-md-3 col-sm-12">
        					<div className="card-img text-center">
                    <a href='https://www.arizona.edu' target='_blank' rel="noopener noreferrer">
  						        <img alt='sample' src={uaImage} className="img-fluid" />
                    </a>
        					</div>
        				</div>
                <div className="col-md-9 col-sm-12">
      						<h4 className="card-title">University of Arizona</h4>
      						<h6 className="card-title">August 2004 – May 2005</h6>
      						<div className="card-text">
                    <strong>Studied:</strong>
                    <ul>
                      <li>Computer Engineering</li>
                    </ul>
                  </div>
        				</div>
        			</div>

  					</div>
  					<div className="card-footer">
  						<a className="card-link" data-toggle="collapse" href="#uaDetails" role="button" aria-expanded="false" aria-controls="#uaDetails">
                Read more<i className="ml-2 fa fa-arrow-down" />
              </a>

              <div className="collapse" id="uaDetails">
                <div className="card card-body">
                  Here I took many computer science classes that helped build a foundation for my carrer in Software Engineering
                </div>
              </div>

  					</div>
  				</div>
  			</div>
  		</div>
    );
  }
}

export default UACard;
