import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import './App.css';

import NavBar from './NavBar'
import Footer from './Footer'
import Home from './Home'
import Privacy from './Privacy'
import Terms from './Terms'
import BlogArticle from './BlogArticle'
import ContactMe from './ContactMe'

class App extends Component {
  render() {
    return (
      <div className="App">
        <div id="home" className="anchor"></div>
        <NavBar />

        <Route exact={true} path="/" component={Home}/>
        <Route exact={true} path="/blog" component={BlogArticle}/>
        <Route exact={true} path="/contact" component={ContactMe}/>
        <Route exact={true} path="/privacy" component={Privacy}/>
        <Route exact={true} path="/terms" component={Terms}/>

        <Footer />
      </div>
    );
  }
}

export default App;
