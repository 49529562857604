import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './BlogCard.css';

class BlogCard extends Component {
  render() {
    return (
  		<div className="card mb-5">
  			<div className="card-img">
  				<img alt='sample' src={this.props.image} className="img-fluid" />
  			</div>

  			<div className="card-body">
  				<h4 className="card-title">{this.props.title}</h4>
  				<p className="card-text">{this.props.summary}</p>
  			</div>
  			<div className="card-footer">
          <Link to="/blog">
            <span className=''>
              Read More
            </span>
          </Link>
  			</div>
  		</div>
    );
  }
}

export default BlogCard;
