import React, { Component } from 'react';
import asuImage from'./images/asu.png';

class ASUCard extends Component {
  render() {
    return (
  		<div className="row mt-3">
  			<div className="col-12">
  				<div className="card">
  					<div className="card-body">

          		<div className="row">
                <div className="col-md-3 col-sm-12">
        					<div className="card-img text-center">
                    <a href='https://www.asu.edu' target='_blank' rel="noopener noreferrer">
  						        <img alt='sample' src={asuImage} className="img-fluid" />
                    </a>
        					</div>
        				</div>
                <div className="col-md-9 col-sm-12">
      						<h4 className="card-title">Arizona State University</h4>
      						<h6 className="card-title">August 2005 – December 2010</h6>
      						<div className="card-text">
                    <strong>Studied:</strong>
                    <ul>
                      <li>Computer Engineering</li>
                      <li>Biomedical Engineering</li>
                      <li>Genomics and Bioinformatics</li>
                    </ul>
                    <strong>Degree:</strong> Bachelors of Science in Genomics and Bioinformatics
                  </div>
        				</div>
        			</div>

  					</div>
  					<div className="card-footer">
  						<a className="card-link" data-toggle="collapse" href="#asuDetails" role="button" aria-expanded="false" aria-controls="#asuDetails">
                Read more<i className="ml-2 fa fa-arrow-down" />
              </a>

              <div className="collapse" id="asuDetails">
                <div className="card card-body">
                  ASU is a thriving university that has a lot to offer.  It was here that I learned everything from Genetics to High Performance Computing.
                  Here I worked through various university laboratories while attending classes.
                </div>
              </div>

  					</div>
  				</div>
  			</div>
  		</div>
    );
  }
}

export default ASUCard;
