import React, { Component } from 'react';
import PublicationCard from './PublicationCard';
import './Publications.css';

class Publications extends Component {
  render() {
    return (
      <div className="publications">
        <div id="publications" className="anchor"></div>
      	<div className="container">
      		<h1 className="text-center section-title">Publications</h1>

          <PublicationCard
            id="1"
            title="Association of orthodenticle with natural variation for early embryonic patterning in Drosophila melanogaster."
            authors="Goering LM, Hunt PK, Heighington C, Busick C, Pennings PS, Hermisson J, Kumar S, Gibson G."
            publication_date="2009 December 15"
            journal="Journal of Experimental Zoology - Molecular and Developmental Evolution"
            journal_link="https://www.wiley.com/en-us/Journal+of+Experimental+Zoology+Part+B%3A+Molecular+and+Developmental+Evolution-p-9780JRNL77266"
            publication_link="https://onlinelibrary.wiley.com/doi/10.1002/jez.b.21299"
            summary="Although it is well established that cis‐acting regulatory variation contributes to morphological evolution between species, few concrete examples of polymorphism affecting developmental patterning within species have been demonstrated. Early embryogenesis in Drosophila is initiated by a gradient of Bicoid morphogen activity that results in differential expression of multiple target genes. In a screen for genetic variation affecting this process, we surveyed 96 wild‐type lines of Drosophila melanogaster for polymorphisms in binding sites within 16 Bicoid cis‐regulatory response elements. One common polymorphism in the orthodenticle (otd) early head enhancer is associated with a complex series of indels/substitutions that define two distinct haplotypes. The middle region of this enhancer exhibits an unusual pattern of nucleotide diversity that does not easily fit into standard models of selection and demography. Population Gene Expression Maps, generated by extracting binary expression profiles from normalized embryo images, revealed a ventral reduction of otd transcript abundance in one of the haplotypes that was recapitulated in expression of transgenic constructs containing the two alleles. We thus demonstrate that even a process as robust as early developmental patterning is affected by standing genetic variation, intriguingly involving otd, whose morphogenetic function bicoid is thought to have displaced during dipteran evolution."
            />
          <PublicationCard
            id="2"
            title="BING: biomedical informatics pipeline for Next Generation Sequencing."
            authors="Kriseman J, Busick C, Szelinger S, Dinu V."
            publication_date="2010 June"
            journal="Journal of Biomedical Informatics"
            journal_link="https://www.journals.elsevier.com/journal-of-biomedical-informatics"
            publication_link="https://www.sciencedirect.com/science/article/pii/S153204640900149X"
            summary="High throughput parallel genomic sequencing (Next Generation Sequencing, NGS) shifts the bottleneck in sequencing processes from experimental data production to computationally intensive informatics-based data analysis. This manuscript introduces a biomedical informatics pipeline (BING) for the analysis of NGS data that offers several novel computational approaches to 1. image alignment, 2. signal correlation, compensation, separation, and pixel-based cluster registration, 3. signal measurement and base calling, 4. quality control and accuracy measurement. These approaches address many of the informatics challenges, including image processing, computational performance, and accuracy. These new algorithms are benchmarked against the Illumina Genome Analysis Pipeline. BING is the one of the first software tools to perform pixel-based analysis of NGS data. When compared to the Illumina informatics tool, BING’s pixel-based approach produces a significant increase in the number of sequence reads, while reducing the computational time per experiment and error rate (<2%). This approach has the potential of increasing the density and throughput of NGS technologies."
            />
          <PublicationCard
            id="3"
            title="FlyExpress: visual mining of spatiotemporal patterns for genes and publications in Drosophila embryogenesis."
            authors="Kumar S, Konikoff C, Van Emden B, Busick C, Davis KT, Ji S, Wu LW, Ramos H, Brody T, Panchanathan S, Ye J, Karr TL, Gerold K, McCutchan M, Newfeld SJ."
            publication_date="2011 December 1"
            journal="Bioinformatics"
            journal_link="https://academic.oup.com/bioinformatics"
            publication_link="https://academic.oup.com/bioinformatics/article/27/23/3319/236381"
            summary="Images containing spatial expression patterns illuminate the roles of different genes during embryogenesis. In order to generate initial clues to regulatory interactions, biologists frequently need to know the set of genes expressed at the same time at specific locations in a developing embryo, as well as related research publications. However, text-based mining of image annotations and research articles cannot produce all relevant results, because the primary data are images that exist as graphical objects. We have developed a unique knowledge base (FlyExpress) to facilitate visual mining of images from Drosophila melanogaster embryogenesis. By clicking on specific locations in pictures of fly embryos from different stages of development and different visual projections, users can produce a list of genes and publications instantly. In FlyExpress, each queryable embryo picture is a heat-map that captures the expression patterns of more than 4500 genes and more than 2600 published articles. In addition, one can view spatial patterns for particular genes over time as well as find other genes with similar expression patterns at a given developmental stage. Therefore, FlyExpress is a unique tool for mining spatiotemporal expression patterns in a format readily accessible to the scientific community."
            />
          <PublicationCard
            id="4"
            title="Patterns of relating between physicians and medical assistants in small family medicine offices."
            authors="Elder NC, Jacobson CJ, Bolon SK, Fixler J, Pallerla H, Busick C, Gerrety E, Kinney D, Regan S, Pugnale M."
            publication_date="2014 March-April"
            journal="Annals of Family Medicine"
            journal_link="http://www.annfammed.org"
            publication_link="http://www.annfammed.org/content/12/2/150"
            summary="PURPOSE The clinician-colleague relationship is a cornerstone of relationship-centered care (RCC); in small family medicine offices, the clinician–medical assistant (MA) relationship is especially important. We sought to better understand the relationship between MA roles and the clinician-MA relationship within the RCC framework.
METHODS We conducted an ethnographic study of 5 small family medicine offices (having <5 clinicians) in the Cincinnati Area Research and Improvement Group (CARInG) Network using interviews, surveys, and observations. We interviewed 19 MAs and supervisors and 11 clinicians (9 family physicians and 2 nurse practitioners) and observed 15 MAs in practice. Qualitative analysis used the editing style.
RESULTS MAs’ roles in small family medicine offices were determined by MA career motivations and clinician-MA relationships. MA career motivations comprised interest in health care, easy training/workload, and customer service orientation. Clinician-MA relationships were influenced by how MAs and clinicians respond to their perceptions of MA clinical competence (illustrated predominantly by comparing MAs with nurses) and organizational structure. We propose a model, trust and verify, to describe the structure of the clinician-MA relationship. This model is informed by clinicians’ roles in hiring and managing MAs and the social familiarity of MAs and clinicians. Within the RCC framework, these findings can be seen as previously undefined constraints and freedoms in what is known as the Complex Responsive Process of Relating between clinicians and MAs.

CONCLUSIONS Improved understanding of clinician-MA relationships will allow a better appreciation of how clinicians and MAs function in family medicine teams. Our findings may assist small offices undergoing practice transformation and guide future research to improve the education, training, and use of MAs in the family medicine setting."
          />

      	</div>
      </div>
    );
  }
}

export default Publications;
