import React, { Component } from 'react';
import ExperienceCard from'./ExperienceCard';
import './Experience.css';

import flyImage from'./images/fly.jpg';
import labImage from'./images/lab.jpg';
import bionicImage from'./images/bionic.jpg';
import warehouseImage from'./images/warehouse.jpg';
import surgeryImage from'./images/surgery.jpg';
import tangaImage from'./images/Tanga.jpg';
import discountMagsImage from'./images/DiscountMags.jpg';

class Experience extends Component {
  render() {
    return (
      <div className="experience">
        <div id="experience" className="anchor"></div>
      	<div className="container">
      		<h1 className="text-center section-title">Experience</h1>
          <ExperienceCard
            id='1'
            dates="May 2006 – June 2008 (~2 years)"
            title="Evolutionary Functional Genomics Lab - Biodesign Institute &#64; ASU"
            bullets={['Database Development/Maintenance (Oracle)', 'Website Development (PHP, Apache, JS)' ,'High Performance Image Processing (C/C++)']}
            body="I started my career in software engineering here and learned all about databases, websites, image processing, and analytics."
            image={flyImage} />
          <ExperienceCard
            id='2'
            dates="July 2008 – September 2009 (~1 year)"
            title="Dinu-Lab (Biomedical Informatics Lab) &#64; ASU"
            bullets={['Developemnt of Federation Database systems', 'Image Analytics & Processing', 'Next Generation DNA Sequencing software Pipelines']}
            body="I worked on Next Generation DNA Sequencing projects as well as cancer screening systems"
            image={labImage} />
          <ExperienceCard
            id='3'
            dates="August 2009 – March 2011 (~1.5 years)"
            title="Human Machine Symbiosis Lab &#64; ASU"
            bullets={['Immersive Simulation Software Development', 'Haptic Device Development (sensors, motors, microcontrollers)', 'Used 3D Game Engines (Unreal Engine)']}
            body="Here I worked on integrating sensors and feedback systems with patients"
            image={bionicImage} />
          <ExperienceCard
            id='4'
            dates="October 2010 – December 2011 (~1 year)"
            title="KASM (a Johnson & Johnson Startup)"
            body="This was a startup company of just a few people.  We created an immersive surgical simulator that used sensors, motors (for haptic-feedback), 3D glasses (for immersive experience), micro-controllers, and game-development software."
            bullets={['Created a Orthopedic Surgical Simulator', 'Worked with leading surgeons to improve simulator']}
            image={surgeryImage} />
          <ExperienceCard
            id='5'
            dates="January 2012 – April 2016 (~2.5 years)"
            title="Liberty Distribution (Vistar - PFGC)"
            bullets={['Developed web-based Warehouse Management Software', 'Developed and Optimized Enterprise Resource Planning software', 'Worked through technical due diligence processes for acquisition and subsequent IPO']}
            body="Created and maintained B2B SPM and ERP systems"
            image={warehouseImage} />
          <ExperienceCard
            id='6'
            dates="April 2016 – June 2018 (~2 years)"
            title="DiscountMags.com"
            body="Maintained and enhanced an e-commerce business for discounted magazine subscriptions"
            bullets={['Database development and optimization (MySQL)', 'Website design and development (PHP, React, Bootstrap)']}
            image={discountMagsImage} />
          <ExperienceCard
            id='7'
            dates="April 2016 – Current (~2.5 years)"
            title="Tanga.com, Bellechic.com"
            body="I currently maintain and continue to develop the many systems that comprise of this drop-ship e-commerce business."
            bullets={['Database development and optimization (Postgres, MySQL, Redis)', 'Website design and development (Ruby/Rails, React, Bootstrap)', 'Email Marketing System Design']}
            image={tangaImage}
            details="Growth, Optimization, Profitability are they key principles of my work at Tanga." />
      	</div>
      </div>
    );
  }
}

export default Experience;
