import React, { Component } from 'react';
import './NavBar.css';
import { Link } from "react-scroll";


class NavBar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">

        <a href="/">
          <span className="navbar-brand circle-brand">
            <strong>CB</strong>
          </span>
        </a>

        <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="home">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">Home</span>
              </Link>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="about">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">About Me</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="skills">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">Skills</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="blog">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">Blog</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="education">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">Education</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="portfolio">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">Portfolio</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="publications">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">Publications</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="experience">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">Experience</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="contact">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">Contact Me</span>
              </Link>
            </li>

          </ul>
        </div>
      </nav>
    );
  }
}

export default NavBar;
