import React, {Component} from 'react'

class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      site_name: "cbusick.com"
    }
  }

  render() {
    return <div id='body' className='header-margin'>
      <div className='container'>
      <div className='row'>
      <div className='col-12 mx-auto'>
        <div>
        	<h2 className=''>Privacy Policy</h2>
        	<div className='text-left'>
        		<center>{this.state.site_name}<br/>PRIVACY POLICY</center>
        		<p><strong>1. Scope</strong></p>
        		<p>{this.state.site_name} (“{this.state.site_name}”), owns and operates the web site {this.state.site_name} (the “Site”) and is the sole owner of the information collected on the Site.</p>
        		<p>This Privacy Policy covers how we handle your personally identifiable information (“Personal Data”) on the Site. {this.state.site_name} knows that users care about how their personal information is used and shared, and we take your privacy seriously. Please
        			read the following to learn more about our privacy policy.</p>
        		<p>By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy. If you provide Personal Data to the Site, you acknowledge and agree that such Personal Data may be transferred from your current
        			location to the offices and servers of {this.state.site_name} and the authorized third parties referred to herein.</p>
        		<p><strong>2. Information collected through the Site.</strong></p>
        		<p>If you choose to use extended features of our Site, you must first complete the registration form and create a username and password. During registration and use of the Site, you are required to give contact information and other Personal Data. Such
        			types of information, and the points at which they are collected, include:</p>
        		<p>A. Personal Data</p>
        		<p>[1] Registered Users. For Users wishing to make use of extended features of our Site, registration may be required where you give us some Personal Data which may include your name and email address. Such registration information is used to provide access
        			to the Site and contact you when necessary.</p>
        		<p>[2] Non-Registered Users. While no Personal Data may be requested of Users wishing to make use of our Site without registering, other data may be collected in association with use of our Site. This information is described below.</p>
        		<p>B. Other Information </p>
        		<p>[1] Log Files. As is true of most websites, we gather certain information automatically and store it in log files. This information includes internet protocol (“IP”) addresses, browser type, internet service provider (“ISP”), referring/exit pages, operating
        			system, date/time stamp, and clickstream data. We use this information, which does not identify individual users, to analyze trends, to administer the site, to track users’ movements around the site and to gather demographic information about our user
        			base as a whole. Although we do not routinely associate this data with records containing Personal Data, we will use IP addresses to identify any user who refuses to comply with our Terms of Use and to identify users who threaten our service, Site,
        			or other users.</p>
        		<p>[2] Cookies. A cookie is a small text file that is stored on a user’s computer for record-keeping purposes. We use cookies on this site and do not link this information stored in cookies to personally identifiable information you submit while on our
        			site. We may use both session ID cookies and/or persistent cookies as part of the Site’s functionality. A session ID cookie expires when you close your browser and allows the Site to display your current session. A persistent cookie remains on your
        			hard drive for an extended period of time and may be used to store your username or other identifier used by the Site. You can remove persistent cookies by following directions provided in your Internet browser’s “help” file.<br/>Most browsers have
        			an option for turning off the cookie feature, which will prevent your browser from accepting new cookies, as well as allowing you to decide on acceptance of each new cookie in a variety of ways. We strongly recommend that you leave the cookies activated
        			in order to ensure the proper functionality of the Site.</p>
        		<p><strong>3. Our Use of Your Personal Data and Other Information</strong></p>
        		<p>We use the Personal Data you provide in a manner that is consistent with this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, registration
        			information submitted when creating your account, may be used to help suggest products to you based on past purchases. Also, we may use your Personal Data to provide access to services on the Site and monitor your use of such services. {this.state.site_name}
        			may also use your Personal Data and other personally non-identifiable information collected through the Site to help us improve the content and functionality of the Site, to better understand our users, and to improve our services.</p>
        		<p><strong>4. Our Disclosure of your Personal Data and Other Information</strong></p>
        		<p>A. {this.state.site_name} is not in the business of selling your Personal Data. We consider this information to be a vital part of our relationship with you and will not disclose your Personal Data to a third party except as set forth in this Privacy Policy.
        			There are, however, certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below:</p>
        		<p>[1] Business Transfers. As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.</p>
        		<p>[2] Agents, Consultants, and Related Third Parties. {this.state.site_name}, like many businesses, sometimes hires other companies to perform certain business-related functions. We may use third party companies to provide mailing services, database maintenance,
        			payment processing, and identity verification. When you register for the Site, we will only share your Personal Data, such as your email, necessary for the third party to provide such services. Those third parties are prohibited from using your personally
        			identifiable information for any other purpose.<br/>Other than the agents, consultants and related third parties described above, {this.state.site_name} will not share, rent or trade Personal Data with any third parties for their promotional purposes.</p>
        		<p>[3] Legal Requirements. We reserve the right to disclose your Personal data as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, or legal process served on us
        			or our Site.<br/>{this.state.site_name} may also disclose your Personal Data in the good faith belief that such action is necessary to (i) protect and defend the rights or property of {this.state.site_name}, (ii) act in urgent circumstances to protect the personal
        			safety of users of the Site or the public, or (iii) protect against legal liability.</p>
        		<p>[4] Consent. Except as set forth above, you will be notified when your personal Data may be shared with third parties, and will be able to prevent the sharing of this information.<br/>If your Personal Data changes, or if you no longer desire our service,
        			you may correct, update, delete or deactivate it by making the change on the Site. Alternatively, you may email our Customer Support staff or contact us by telephone or regular mail at the contact information listed below.</p>
        		<p><strong>5. External Links</strong></p>
        		<p>Our Site may provide links to various third party websites. However, even if a third-party affiliation exists between our site and that destination site, we do not exercise authority over these linked sites, each of which maintains independent privacy
        			and data collection policies and procedures. We do not assume responsibility or liability and are not responsible for the independent policies and procedures of destination sites. Similarly, we cannot take responsibility for the privacy initiatives
        			or the content of such websites. However, we wish to ensure the integrity of our site and any destination links, so any comments pertaining to any website accessed through these links are greatly appreciated.</p>
        		<p><strong>6. Exclusions</strong></p>
        		<p>This Privacy Policy does not apply to any information collected by {this.state.site_name} other than Personal Data collected through the Site. This Privacy Policy shall not apply to any unsolicited information you provide to {this.state.site_name} through the Site
        			or through any other means. This includes, but is not limited to, information posted to any public area of the Site, including the comments, forum, and review sections of the Site (collectively, “Public Areas”). Please think carefully before disclosing
        			Personal Data in a Public Area. All such information is not confidential and {this.state.site_name} shall be free to reproduce, use, disclose, and distribute such information to others without limitation or attribution and it may be used by others in ways
        			we cannot prevent.</p>
        		<p><strong>7. Children</strong></p>
        		<p>{this.state.site_name} respects the sensitive nature of children’s privacy online. We do not direct any of our content or services, not do we knowingly collect Personal Data from children under the age of 13. If you are under thirteen, please do not submit
        			any Personal Data through the Site. If you have reason to believe that a child under the age of 13 has provided Personal Data to {this.state.site_name} through the Site, please contact us and we will endeavor to delete that information from our databases.</p>
        		<p><strong>8. Security</strong></p>
        		<p>We employ reasonable security measures to protect against the loss, misuse, and alteration of the personal Data under our control. Personal Data is stored in a secured database and always sent via an encrypted SSL protocol when supported by your browser.
        			However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Site may not be secure. Therefore, you should take special care in deciding what information you send to us via email.</p>
        		<p><strong>9. Opt-Out</strong></p>
        		<p>We provide you the opportunity to “opt-out” of having your personally identifiable information used for certain purposes, when we ask you for this information. For example, when you register for the Site, if you do not wish to receive any additional
        			material or notifications from us, you can indicate your preference on our registration form.</p>
        		<p><strong>10. Framing</strong></p>
        		<p>At times, the Site may make use of “framing” technology, during which a frame within the window of the Site is actually displaying the content of another website not owned or operated by {this.state.site_name}. You should read the privacy policy of such other
        			website as it will govern the information that is collected there.</p>
        		<p><strong>11. Other Terms and Conditions</strong></p>
        		<p>Your access to and use of the Site is subject to the Terms of Use. The Site is intended for United States users and United States law governs all transactions conducted on the Site and any use of materials found on the Site.</p>
        		<p><strong>12. Contacting Us:</strong></p>
        		<p>If you have any questions about this Privacy Policy, the practices of the Site, or your interaction with the Site, please contact us by clicking on the “Contact” link at the bottom of the page or at the following address:</p>
        		<p>{this.state.site_name}</p>
        		<p><strong>13. Changes to this Privacy Policy</strong><br/>{this.state.site_name} reserves the right to modify this Privacy Policy from time to time, so please review it frequently. If we make material changes in the way we use Personal Data, we will notify
        			you here, by email, or by posting an announcement on the home page of our Site. Your continued use of the Site after such changes are posted will indicate your acceptance of the changed terms.</p>
        	</div>
        </div>
      </div>
      </div>
      </div>
    </div>
  }
}

export default Privacy;
