import React, { Component } from 'react';
import $ from 'jquery';

import './Skills.css';

class Skills extends Component {
  componentDidMount() {
  $('[data-toggle="tooltip"]').tooltip();
}

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  render() {
    return (
      <div className="skills">
        <div id="skills" className="anchor"></div>
      	<div className="container">
      		<h1 className="text-center section-title">Skills / Technologies</h1>

      	  <div className="row">
        	  <div className="col-12">
              <div className="d-flex justify-content-center">
                <ul className='skill-list'>
                  <li data-toggle="tooltip" data-placement="bottom" title="Best in class Infrastructure Service Provider that I use everyday">
                    <i className="skill-icon devicon-amazonwebservices-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="The best OS for running everything">
                    <i className="skill-icon devicon-linux-plain colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="A simple powerfull RDBMS for anything">
                    <i className="skill-icon devicon-mysql-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="An real workhorse RDMBS">
                    <i className="skill-icon devicon-postgresql-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Fast Key-Value store for caching and async job processing">
                    <i className="skill-icon devicon-redis-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="A nice webserver">
                    <i className="skill-icon devicon-apache-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Rock solid webserver / reverse-proxy">
                    <i className="skill-icon devicon-nginx-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Layers of fun. No more 'works on my machine'">
                    <i className="skill-icon devicon-docker-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Finally, a clean UI framework">
                    <i className="skill-icon devicon-bootstrap-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="My favorite langauge. Best for backend business logic">
                    <i className="skill-icon devicon-python-plain-wordmark"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Flexible Fun langauge.  Used for just about everything">
                    <i className="skill-icon devicon-ruby-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Solid language for websites">
                    <i className="skill-icon devicon-php-plain colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="The JS library that makes actions fun">
                    <i className="skill-icon devicon-jquery-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Like async procs?">
                    <i className="skill-icon devicon-nodejs-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Gotta bunch of JS libs? Time to bundle.">
                    <i className="skill-icon devicon-webpack-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="SPAs, reusable components, structured.">
                    <i className="skill-icon devicon-react-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Nothing can not be done with VIM">
                    <i className="skill-icon devicon-vim-plain colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="A very nice IDE that I use daily">
                    <i className="skill-icon devicon-atom-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="A nice communication tool">
                    <i className="skill-icon devicon-slack-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="You gotta have nice tools to make nice things">
                    <i className="skill-icon devicon-apple-plain colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="My default browser">
                    <i className="skill-icon devicon-chrome-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Better fonts, selectors, properties, etc. More things to remember">
                    <i className="skill-icon devicon-css3-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="Keeping up with the times">
                    <i className="skill-icon devicon-html5-plain-wordmark colored"></i>
                  </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="A good place to keep and share your code">
                    <i className="skill-icon devicon-github-plain-wordmark colored"></i>
                  </li>
          	    </ul>
          	  </div>
          	</div>
        	</div>

        </div>
      </div>
    );
  }
}

export default Skills;
