import React, { Component } from 'react';
import './Education.css';

import UACard from './UACard';
import ASUCard from './ASUCard';

class Education extends Component {
  render() {
    return (
      <div className="education">
        <div id="education" className="anchor"></div>
      	<div className="container">
      		<h1 className="text-center section-title">Education</h1>
          <UACard />
          <ASUCard />
      	</div>
      </div>
    );
  }
}

export default Education;
