import React, { Component } from 'react';
import './Portfolio.css';

import BellechicImage from'./images/Bellechic.jpg';
import TangaImage from'./images/Tanga.jpg';
import DiscountMagsImage from'./images/DiscountMags.jpg';
import FlyExpressImage from'./images/FlyExpress.jpg';
/* import PortfolioCard from './PortfolioCard'; */

class Portfolio extends Component {

  render() {
    return (
      <div className="App-Header portfolio">
        <div id="portfolio" className="anchor"></div>
        <div className="container">
          <h1 className="text-center section-title">Portfolio</h1>

          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div className="carousel-inner">

              <div className="carousel-item active">
                <img className="d-block mb-4 mx-auto img-fluid" src={TangaImage} alt="First slide" />
                <div className="carousel-footer">
                  <h5><a href='https://www.tanga.com' target='_blank' rel="noopener noreferrer">Tanga.com</a></h5>
                  <p>Ecommerce website for Daily Deals</p>
                </div>
              </div>

              <div className="carousel-item">
                <img className="d-block mb-4 mx-auto img-fluid" src={BellechicImage} alt="Second slide" />
                <div className="carousel-footer">
                  <h5><a href='https://www.bellechic.com' target='_blank' rel="noopener noreferrer">Bellechic.com</a></h5>
                  <p>Ecommerce website for Chic Deals</p>
                </div>
              </div>

              <div className="carousel-item">
                <img className="d-block mb-4 mx-auto img-fluid" src={DiscountMagsImage} alt="Third slide" />
                <div className="carousel-footer">
                  <h5><a href='https://www.discountmags.com' target='_blank' rel="noopener noreferrer">DiscountMags.com</a></h5>
                  <p>Ecommerce website Discount Magazine Subscriptions</p>
                </div>
              </div>

              <div className="carousel-item">
                <img className="d-block mb-4 mx-auto img-fluid" src={FlyExpressImage} alt="Third slide" />
                <div className="carousel-footer">
                  <h5><a href='http://flyexpress.net' target='_blank' rel="noopener noreferrer">FlyExpress.net</a></h5>
                  <p>Research Website for Developmental Genetics</p>
                </div>
              </div>

            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <i className='fa fa-arrow-left fa-2x' aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <i className='fa fa-arrow-right fa-2x' aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>

        </div>
      </div>
    );
  }
}

export default Portfolio;
